<template>
  <div class="eula">
    <!-- <p class="text-body-1">Eastman Chemical Company</p>
    <p class="centered text-h4 font-weight-bold">FastER end use agreement</p>
    <p class="text-h6 font-weight-bold">THIS FastER End Use Agreement (“AGREEMENT”) IS A LEGALLY BINDING AGREEMENT BETWEEN THE LICENSEE (AS DESIGNATED BELOW) AND EASTMAN CHEMICAL COMPANY (“EASTMAN”).  BY CLICKING THE “I Agree” BUTTON AT THE END OF THIS AGREEMENT AND BY ACCESSING THE FastER SOFTWARE (“SOFTWARE”), LICENSEE REPRESENTS AND WARRANTS THAT LICENSEE HAS READ AND UNDERSTANDS THIS AGREEMENT IN ITS ENTIRETY AND AGREES TO BE BOUND BY AND BECOME A PARTY TO THIS AGREEMENT.</p>
    <p class="text-body-1">Eastman’s acceptance and fulfillment of its obligations are expressly conditioned on acceptance and agreement by Licensee to these terms and conditions. If you DO NOT AGREE to all of the terms and conditions of this Agreement, you must click the “Cancel” button to indicate that you do not accept the terms and conditions of this Agreement and thereafter you will not be able to access the Software and must not attempt to do so.</p>
    <p class="text-body-1">Carefully read this Agreement for the license of Eastman’s FastER you are about to access (via website).  “Licensee” means the individual person designated below accessing and using the Software on his or her own behalf, or, if the Software is being accessed on behalf of an organization designated below, such as an employer, “Licensee” means the organization for which the Software is accessed, and the person accessing or using the Software represents that he or she has the authority to do so on your behalf.</p>
    <v-divider class="mb-8"/>
    <p class="text-body-1">This Agreement is effective as of the date (“Effective Date”) this Agreement is accepted and is by and between your company or business and you individually as an authorized user (“Licensee”) and Eastman Chemical Company, a Delaware company, with offices at 200 S. Wilcox Drive, Kingsport, TN 37660.</p>
    <p class="text-body-1">Eastman has developed software which utilizes inputs from the users (i.e., desired dissolution) and suggests a starting formulation using Eastman developed algorithms and models. The software suggests a blend and a ratio of Eastman polymers to be used, along with the coating percentage by weight.</p>
    <p class="text-body-1">Licensee wishes to license the Software to receive a suggested starting formulation for the desired dissolution, which is the input to the Software (“Purpose”).</p>-->
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="pdfsrc"
      :page="i"
    ></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

var loadingTask = pdf.createLoadingTask('FastER_end_user_agreement.pdf')

export default {
  Name: 'EulaText',
  components: {
    pdf,
  },
  data: function () {
    return {
      pdfsrc: loadingTask,
    numPages: undefined,
    }
  },
  mounted: function () {
    this.pdfsrc.promise.then(doc => {
      this.numPages = doc.numPages;
    });
  },
}
</script>

<style lang="scss" scoped>

.centered {
  text-align: center;
}

</style>
